let init = () => {
    $(window).on("scroll", () => {
        if ($(window).scrollTop()) {
            $("#navbar").addClass("bg-dark");
        } else {
            $("#navbar").removeClass("bg-dark");
        }
    });
};

export { init as initNavbar };
