import Swiper from "swiper/bundle";

let sectionHome = document.querySelector("#home");

let initSwiper = () => {
    let swiperSelector = "#swiperHero";
    return new Swiper(swiperSelector, {
        resistanceRatio: 0,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
    });
};

let init = () => {
    initSwiper();

    window.scrollToHome = function () {
        window.scrollTo({
            behavior: "smooth",
            top: $(sectionHome).offset().top - 92,
        });
    };
};

export { init as initHero };
